<template>
  <div class="application form">
    <div class="application__title">
      <h2>
        Application Form 
      </h2>
      <div
        v-if="step.status && step.status === 'In Review'"
        class="application__status"
      >
        <span>
          {{step.status}}
        </span>
        <span
          class="step-info"
          :id="'step-info'+step.id"
        >!</span>
        <b-tooltip 
          :target="'step-info'+step.id"
          triggers="hover"
          custom-class="step-info__tooltip"
        >
          This step is in review. You will receive the notification when it is done.
        </b-tooltip>
      </div>
    </div>
    <h2 class="application__program">Program name: <span>{{ programName }}</span></h2>

    <AlertMessage
      v-if="step.info_message"
      :message="step.info_message"
    />

    <b-spinner label="Loading" v-if="isLoading"></b-spinner>
    
    <div
      v-else
    >
      <ApplicationPaymentContract
        v-if="step.hasPC && step.display_pc_on_top"
        :hide-price-breakdown="step.hide_price_breakdown"
        :step-status="step.status"
        @viewableCC="val => this.hideCCForPC = val"
      />

      <ApplicationAddRow
        v-for="field in formFields"
        :ref="field.ref"
        v-show="!field.hidden"
        :key="field.id"
        :fields-data="field"
        :field-value="field.value"
        :step-status="step.status"
        :step-id="step.id"
        :step-requirement-option="step.requirement_option"
        :step-has-payment-wizard="paymentFields.length > 0"
        :valid-tracker="validTracker"
        :check-value="field.hidden"
        :is-disabled-editing="!step.isEditable"
      />

      <ApplicationPaymentContract
        v-if="step.hasPC && !step.display_pc_on_top"
        :hide-price-breakdown="step.hide_price_breakdown"
        :step-status="step.status"
        @viewableCC="val => this.hideCCForPC = val"
      />

      <div v-if="paymentFields.length">
        <div class="payment-wizard-total">
          <span>Total</span>
          <span>($){{ totalSum }}</span>
        </div>
      </div>

      <CreditCard
        v-if="step.isEditable && (paymentFields.length || step.hasPC) && !isDisabledCCOnPcStep"
        @authCreditCard="pay"
        :hideCC="isHideCCBlock"
        :btnText="isLastStep ? this.programCompleteRegistrationBtnText : 'Pay'"
        :is-btn-disabled="this.paymentContract && this.paymentContract.optionInvalid"
      />

      <p class="form__error">{{errorMessage}}</p>

      <div
        v-if="validationErrors.length"
        class="form-group"
      >
        <p
          v-for="(validationError, index) in validationErrors"
          :key="index"
          class="text-center form__error"
        >
          {{ validationError }}
        </p>
      </div>

      <div
        v-if="showButtons || !step.isEditable"
        class="form__row form__one-row"
      >
        <span
          v-if="tabIndex === 0"
          class="btn btn--color-outline-inactive form__button input-button_left"
        >Previous</span>
        <span
          v-if="tabIndex > 0"
          class="btn btn--color-outline-dark form__button input-button_left"
          @click="$store.commit('applicationRegistration/DECREMENT_TAB_INDEX')"
        >Previous</span>
        <button
          v-if="((step.requirement_option === 'Requires Approval To Continue' && step.status !== 'Approved') || isLastStep) && !step.isEditable"
          :disabled="true"
          class="btn btn--color-dark form__button"
        >
          <span>{{ sendButtonText }}</span>
        </button>
        <span
          v-else-if="!step.isEditable"
          class="btn btn--color-dark form__button"
          @click="$store.commit('applicationRegistration/INCREMENT_TAB_INDEX')"
        >{{ sendButtonText }}</span>
        <button
          v-else
          :disabled="isSendButtonDisabled"
          class="btn btn--color-dark form__button"
          @click="sendStepForm()"
        >
          <span>{{ sendButtonText }}</span>
        </button>
      </div>

      <div v-if="showButtons && step.isEditable">
        <button
          v-if="step.status !== 'Approved'"
          :disabled="isSaveButtonDisabled"
          class="btn btn--color-outline-dark form__button btn-continue"
          @click="sendStepForm(true)"
        >Save & Continue Later</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { BSpinner, BTooltip } from 'bootstrap-vue'
  import ApplicationAddRow from '@/views/Application/ApplicationAddRow.vue'
  import ApplicationPaymentContract from '@/views/Application/ApplicationPaymentContract.vue'
  import CreditCard from '@/components/site/CreditCard.vue'
  import AlertMessage from '@/components/site/AlertMessage.vue'
  import { checkConditionValues } from '@core/mixins/checkConditionValues'
  import { instanceSessionCamperNames } from '@core/mixins/instanceSessionCamperNames'
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  import store from "@/store";
  import useApplicationAdd from '@/views/Application/useApplicationAdd'

  export default {
    components: {
      BSpinner,
      BTooltip,

      ApplicationAddRow,
      ApplicationPaymentContract,
      CreditCard,
      AlertMessage,
    },
    props: {
      step: {
        type: Object,
        require: true,
      },
      formFields: {
        type: Array,
        default: () => []
      },
    },
    mixins: [checkConditionValues, instanceSessionCamperNames],
    data() {
      return {
        programId: this.$router.currentRoute.params.id,
        validTracker: false,
        errorMessage: '',
        validationErrors: [],
        isLoading: false,
        isSaveButtonDisabled: false,
        isSendButtonDisabled: false,
        hideCCForPC: false,
      }
    },
    setup() {
      const {
        saveProgress,
      } = useApplicationAdd()

      return {
        saveProgress,
      }
    },
    watch: {
      formFields: {
        deep: true,
        handler() {
          this.allConditions.forEach(condition => {
            if (condition.related_type == 'Field') {
              const parentField = this.formFields.find(row => row.id === condition.parent_field)
              const relatedField = this.formFields.find(row => row.id === condition.related_field)
              
              if (parentField && parentField.hidden === true) {
                relatedField.hidden = true
              }
            }
            
          })
        }
      },
    },
    computed: {
      ...mapGetters({
        applicationId: 'applicationRegistration/getApplicationId',
        userId: 'applicationRegistration/getUserId',
        programName: 'applicationRegistration/getProgramName',
        programCompleteRegistrationBtnText: 'applicationRegistration/getProgramCompleteRegistrationBtnText',
        paymentContract: 'applicationRegistration/getPaymentContract',
        tabIndex: "applicationRegistration/getCurrentTabIndex",
        stepsList: 'applicationRegistration/getStepsList',
        hiddenStepsIds: 'applicationRegistration/getHiddenStepsIds',
        allConditions: 'applicationRegistration/getAllConditions',
        allFormFields: 'applicationRegistration/getAllFormFields',
        lastStep: 'applicationRegistration/getLastStep',
        paymentContractIsExist: 'applicationRegistration/getPaymentContractIsExist',
      }),
      isLastStep() {
        return this.lastStep.id == this.step.id
      },
      isDisabledCCOnPcStep() {
        return ['Approved', 'In Review'].includes(this.step.status)
          && this.step.hasPC
      },
      paymentFields() {
        return this.formFields.filter(field => field.type === 'payment_field' && !field.hidden)
      },
      totalSum() {
        return this.paymentFields.reduce((acc, cur) => {
          return acc + Number(cur ? cur.charge_amount : 0)
        }, 0)
      },
      showButtons() {
        return !this.paymentFields.length && !this.step.has_payment_contract
      },
      isHideCCBlock() {
        return this.hideCCForPC && this.paymentFields.length === 0
      },
      sendButtonText() {
        if (this.isLastStep) return this.programCompleteRegistrationBtnText
        if (['Approved', 'In Review'].includes(this.step.status) || !this.step.isEditable) return 'Next'
        if (['Requires Approval', 'Requires Approval To Continue'].includes(this.step.requirement_option)) return 'Send'
        return 'Continue'
      },
    },
    methods: {
      uploadFile(file, type = 'docs') {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('type', type)

        return store.dispatch('files/uploadFiles', {
          name: `application-files-${new Date().getTime()}`,
          file: formData,
        })
      },
      async validStep() {
        if (this.formFields.filter(field => field.required && !['plain_text', 'signature'].includes(field.type) && (field.value == null || field.value.length == 0) && !field.hidden).length > 0 ) {
          this.errorMessage = 'Please, fill required fields'
          this.validTracker = true
          return
        }

        if (this.formFields.filter(field => field.type === 'group_selection' && field.required && (field.value == null || field.value.length == 0) && !field.hidden).length > 0 ) {
          this.errorMessage = 'Please, fill required fields'
          this.validTracker = true
          return
        }

        if (this.formFields.filter(field => field.type === 'session_selection' && field.required && (field.value == null || field.value.length == 0) && !field.hidden).length > 0 ) {
          this.errorMessage = `Please, select ${this.sessionSemester}`
          this.validTracker = true
          return
        }

        const signatureFields = this.formFields.filter(field => field.type === 'signature' && field.required && !field.hidden)
        if (signatureFields.length > 0 ) {
          signatureFields.forEach(field => {
            const canvasRef = this.findCanvasRefForField(field)
            if (canvasRef && canvasRef.isEmpty() && !canvasRef.loadedImage) {
              this.errorMessage = 'Please, fill required fields'
              this.validTracker = true
            }
          })
          if (this.validTracker) {
            return
          }
        }

        this.validTracker = false

        this.errorMessage = ''
      },
      getPaymentContractData() {
        const option = this.paymentContract.options.find(option => option.id === this.paymentContract.selectedOption)
        if (!option) {
          this.errorMessage = "Select PC option"
          return
        }
        if (option.schedule_option.timeSchedule === 'Split over' && !option.startDate) {
          this.errorMessage = "Select PC option Start Date"
          return
        }

        let preparedData = {
          application_id: this.applicationId,
          condition_id: this.paymentContract.selectedOption,
          condition_type: 'PaymentOption',
          count_of_payments: option.amountOfPayments,
          payment_method: option.selectedMethod,
        }

        if (option.startDate) {
          preparedData.start_date = moment(option.startDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
        }

        return preparedData
      },
      pay(cardData) {
        const paymentPlanData = {
          charges: this.paymentFields,
        }

        if (!this.isHideCCBlock && cardData) {
          paymentPlanData.card = cardData
        }

        if (this.step.hasPC && this.paymentContract) {
          const paymentContractData = this.getPaymentContractData()
          if (!paymentContractData) {
            return
          }
          paymentPlanData.paymentContractData = paymentContractData
        }

        this.sendStepForm(false, paymentPlanData)
      },
      findCanvasRefForField(field) {
        return this.$refs[field.ref][0]?.$refs[`signature_${field.ref}`]?.$refs[field.ref]
      },
      prepareHiddenSteps(isDraft) {
        let hiddenSteps = []
        this.hiddenStepsIds.map(row => {
          if (!row.hiddenFromStepId) {
            row.hiddenFromStepId = this.step.id
          }
          if (isDraft) {
            if (row.hiddenFromStepId !== this.step.id) {
              hiddenSteps.push(row.stepId)
            }
          } else {
            let stepValue = this.stepsList.find(stepResult => stepResult.id === row.hiddenFromStepId)

            if (stepValue && stepValue.step_id === this.step.id) {
              hiddenSteps.push(row.stepId)
            }

            if (stepValue && stepValue.step_status !== 'Draft') {
              hiddenSteps.push(row.stepId)
            }
          }
        })
        return hiddenSteps
      },
      sendStepForm(isDraft = false, paymentPlanData = {}) {
        this.validTracker = false

        if (!isDraft) {
          this.validStep()
        }
        if (this.validTracker) return

        if (isDraft) {
          this.isSaveButtonDisabled = true
        } else {
          this.isSendButtonDisabled = true
        }

        store.commit('SET_LOADING_STATUS', true)

        let fileFieldsCount = 0
        let avatarFieldsCount = 0

        let queryParams = {
          user_id: this.userId,
          program_id: this.$router.currentRoute.params.id,
          step_id: this.step.id,
          fields: [],
          form_data: {},
          is_draft: isDraft,
          form_id: this.step.registration_form_id,
          hidden_steps: this.prepareHiddenSteps(isDraft)
        }

        if (this.applicationId) {
          queryParams.application_id = this.applicationId
        }
        if (paymentPlanData.charges) {
          queryParams.charges = paymentPlanData.charges
        }
        if (paymentPlanData.card) {
          queryParams.card = paymentPlanData.card
        }

        const filesLinks = []
        const filesNames = []
        const avatarLinks = []
        const avatarNames = []

        if (this.step.hasFileField) {
          const fileFields = this.formFields.filter(row => ['avatar', 'file_upload'].includes(row.type) && row.value)

          if (fileFields.length > 0) {
            fileFields.forEach(field => {
              if (Array.isArray(field.value)) {
                Array.from(field.value).forEach(val => {
                  if (val instanceof Blob) {
                    filesLinks.push(this.uploadFile(val))
                  } else {
                    filesNames.push(val)
                  }
                })
              } else {
                if (field.value instanceof Blob) {
                  avatarLinks.push(this.uploadFile(field.value, 'avatar'))
                } else {
                  avatarNames.push(field.value)
                }
              }
            })
          }
        }

        if (this.step.hasSignatureField) {
          const signatureFields = this.formFields.filter(row => row.type === 'signature')
          signatureFields.forEach(field => {
            const canvasRef = this.findCanvasRefForField(field)
            if (canvasRef && !canvasRef.isEmpty()) {
              field.signature = canvasRef.save()
            }
          })
        }

        const requests = []

        const fileRequests = filesLinks.map(link => ({ request: link, type: 'file' }))
        const avatarRequests = avatarLinks.map(link => ({ request: link, type: 'avatar' }))

        requests.push(...fileRequests, ...avatarRequests)


        Promise.all(requests.map(r => r.request))
          .then(responses => responses.forEach((response, index) => {
              const request = requests[index]

              if (!response.file_path) {
                store.commit('SET_LOADING_STATUS', false)
                this.errorMessage = 'Error uploading file'
                throw new Error('Error')
              }

              if (request.type === 'file') {
                filesNames.push(response.file_path)
              } else if (request.type === 'avatar') {
                avatarNames.push(response.file_path)
              }
            }))
          .then(() => {
            this.formFields
                .filter(row => !row.hidden)
                .forEach(row => {
                  if (row.type === 'file_upload' && row.value) {
                    let from = fileFieldsCount
                    let to = fileFieldsCount + row.value.length
                    let sliced = filesNames.slice(from, to)

                    queryParams.fields.push({
                      field_id: row.id,
                      files: sliced,
                    })
                    queryParams.form_data[row.name] = sliced

                    fileFieldsCount = to
                  } else if (row.type === 'avatar' && row.value) {
                    let avatarName = avatarNames[avatarFieldsCount]

                    queryParams.fields.push({
                      field_id: row.id,
                      files: [avatarName],
                    })
                    queryParams.form_data[row.name] = avatarName

                    avatarFieldsCount++
                  } else if(row.type === 'group_selection') {
                    let value
                    if (Array.isArray(row.value)) {
                      value = row.value.map(item => ({ id: item.id, name: item.name }))
                    } else {
                      value = { id: row.value?.id, name: row.value?.name }
                    }
                    queryParams.fields.push({
                      field_id: row.id,
                      result: value,
                    })
                  } else if (row.type === 'payment_field') {
                    queryParams.fields.push({
                      field_id: row.id,
                      result: {
                        payment_plan_activated: row.payment_plan_activated,
                        payment_month_selected: row.payment_month_selected,
                        charge_amount: row.charge_amount,
                      },
                    })
                  } else if (row.type === 'signature' && row.signature) {
                    queryParams.fields.push({
                      field_id: row.id,
                      result: row.signature,
                    })
                    queryParams.form_data[row.name] = row.signature
                  } else {
                    queryParams.fields.push({
                      field_id: row.id,
                      result: row.value,
                    })
                    queryParams.form_data[row.name] = row.value
                  }
                })
                
            if (paymentPlanData.paymentContractData &&
              (!this.paymentContractIsExist || (this.paymentContractIsExist && !this.stepStatus))
            ) {
              queryParams.paymentPlanData =  paymentPlanData.paymentContractData
            }

            this.saveProgress(queryParams)
              .then(response => {
                if (response.status > 400) {

                  if (response.status === 422) {
                    const validationErrors = Object.values(response.data.errors)
                    this.validationErrors = validationErrors.flat()
                  } else {
                    this.errorMessage = response.data.message
                  }

                  if (isDraft) {
                    this.isSaveButtonDisabled = false
                  } else {
                    this.isSendButtonDisabled = false
                  }

                  store.commit('SET_LOADING_STATUS', false)
                  return
                } else {
                  store.commit('applicationRegistration/SET_APPLICATION_STATUS', response.data.application_status)
                  store.commit('applicationRegistration/SET_IS_APPLICATION_ACCEPTED', response.data.is_accepted)

                  this.step.status = response.data.step?.step_status
                  store.dispatch('alertMessage/fetchInfoMessages',{ application_id: response.data.application_id})

                  if (!this.applicationId) {
                    store.commit('applicationRegistration/SET_APPLICATION_ID', response.data.application_id)
                    this.$router.push({
                      name: 'application-create',
                      query: {user_id: this.userId, app_id: this.applicationId}
                    })
                  }

                  this.getAutoAssignedGroups(response.data.application_id)

                  if (!this.paymentContractIsExist && response.data.application_pay_condition) {
                    this.setPaymentContractData(response.data.application_pay_condition)
                  }

                  if (isDraft) {
                    this.isSaveButtonDisabled = false
                  } else {
                    this.isSendButtonDisabled = false
                  }

                  if (this.isLastStep) {
                    store.commit('SET_LOADING_STATUS', false)
                    if (!isDraft) {
                      this.$router.replace({ name: 'home', query: { id: this.applicationId } })
                    }
                  } else {
                    store.commit('SET_LOADING_STATUS', false)
                    this.$emit('nextStep')
                  }
                }
              })
          })
      },
      async getAutoAssignedGroups(applicationId) {
        await store.dispatch('applicationRegistration/fetchAutoAssignedGroupsList', {
          application_id: applicationId,
        })
      },
      async setPaymentContractData(application_pay_condition) {
        store.commit('applicationRegistration/SET_APPLICATION_PAYMENT_OPTION', application_pay_condition)
        store.commit('applicationRegistration/DISABLE_EDITING_STEPS_IF_PAYMENT_CONTRACT_IS_SELECTED')
        store.commit('applicationRegistration/SET_EDITABLE_SETTINGS_FOR_STEPS')
      },
    }
  }
</script>

<style lang="scss">
@import 'src/assets/scss/colors/colors';

.step-info {
  display: block;
  position: relative;
  margin-left: 10px;
  background: $primary;
  text-align: center;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;

  &__tooltip {
    position: absolute;
    top: 30px;
    background: #F6E9EC;
    border-radius: 15px;
    width: 300px;
    padding: 13px 19px;
    color: $primary;
    top: -10px !important;
  }
}
.continue-btn {
  margin: 0;
  height: 44px;
}
</style>